<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'exercises' }"
          >Exercise</router-link
        >
      </v-toolbar-title>
    </v-app-bar>

    <div class="ma-4">
      <div class="align-center d-flex justify-space-between">
        <div class="display-1 font-weight-bold my-6 text-capitalize">
          Update Exercise
        </div>
        <template v-if="!loading"> </template>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mt-6 pa-0">
        <v-form v-if="form" @submit.prevent="submit" ref="form" v-model="valid">
          <v-divider class="pb-5"></v-divider>

          <label class="text-field-label">Name</label>
          <v-text-field
            flat
            solo
            required
            counter
            :rules="rules.name"
            class="mt-2"
            maxlength="255"
            v-model="form.name"
            placeholder="Name"
            :error-messages="form.$getError('name')"
          ></v-text-field>

          <label class="text-field-label">Description</label>
          <v-textarea
            solo
            maxlength="255"
            name="input-7-4"
            v-model="form.description"
            placeholder="Description"
            :error-messages="form.$getError('description')"
          ></v-textarea>

          <label class="text-field-label">Exercise Category</label>
          <v-select
            flat
            solo
            class="mt-2"
            aspect-ratio="1"
            :items="exercise_category_details"
            item-text="label"
            item-value="value"
            placeholder="Select Exercise Category"
            v-model="form.exercise_category_id"
          ></v-select>

          <label class="text-field-label">Exercise Items</label>
          <v-select
            flat
            solo
            v-model="form.exercise_items_id"
            :items="exercise_items"
            item-text="label"
            item-value="value"
            multiple
            chips
            persistent-hint
            placeholder="Select Exercise Items"
            @input="setExerciseItem"
          ></v-select>

          <label class="text-field-label">Video</label>
          <v-select
            flat
            solo
            class="mt-2"
            aspect-ratio="1"
            :items="video_details"
            item-text="label"
            item-value="value"
            placeholder="Select Video"
            v-model="form.video_id"
          ></v-select>

          <label class="text-field-label">Thumbnail</label>
          <v-file-input
            :rules="rules.photo"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Pick a photo"
            :prepend-icon="icons.camera"
            v-model="form.photo"
            :error-messages="form.$getError('photo')"
          ></v-file-input>

          <label class="text-field-label">Before Photo</label>
          <v-file-input
            :rules="rules.before_photo"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Pick a before photo"
            :prepend-icon="icons.camera"
            v-model="form.before_photo"
            :error-messages="form.$getError('before_photo')"
          ></v-file-input>

          <label class="text-field-label">After Photo</label>
          <v-file-input
            :rules="rules.after_photo"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Pick a after photo"
            :prepend-icon="icons.camera"
            v-model="form.after_photo"
            :error-messages="form.$getError('after_photo')"
          ></v-file-input>

          <div class="mt-4">
            <v-btn
              color="primary"
              class="mr-4 px-6"
              height="40px"
              width="70%"
              @click="submit"
              :loading="form.$busy"
              ><v-icon left dark> {{ icons.update }} </v-icon>Update</v-btn
            >
          </div>
          <div class="mt-4">
            <v-btn
              @click="showDeleteModal = true"
              height="40px"
              width="70%"
              color="error"
              ><v-icon left dark> {{ icons.remove }} </v-icon>Delete
              Exercise</v-btn
            >
          </div>
        </v-form>
      </div>
    </div>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="showDeleteModal"
      title="Delete Exercise"
      message="Are you sure you want to delete exercise?"
      @cancel="showDeleteModal = false"
      @confirm="removeExercise"
    ></ConfirmModal>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mdiChevronLeft, mdiTrashCan, mdiUpdate } from '@mdi/js'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mapActions, mapState, mapMutations } from 'vuex'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { each } from 'lodash'

export default {
  name: 'ExerciseDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
  },

  data() {
    return {
      valid: false,
      form: null,
      photoCover: null,
      showDeleteModal: false,
      exercise_items: [],
      video_details: [],
      exercise_category_details: [],
      icons: {
        arrowLeft: mdiChevronLeft,
        remove: mdiTrashCan,
        update: mdiUpdate,
      },
      rules: {
        name: [(v) => !!v || 'Name is required'],
        video: [(v) => !!v || 'Video is required'],
        photo: [(v) => !!v || 'Photo is required'],
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
    }
  },

  computed: {
    ...mapState({
      exerciseDetails: (state) => state.exercise.exerciseDetails,
      exerciseItemCategory: (state) => state.exercise.exerciseItemCategory,
      video: (state) => state.video.list,
    }),
  },

  methods: {
    ...mapActions({
      updateExercise: 'exercise/updateExercise',
      getExerciseDetails: 'exercise/getExerciseDetails',
      deleteExercise: 'exercise/deleteExercise',
      getExerciseItemCategory: 'exercise/getExerciseItemCategory',
      getVideos: 'video/getVideos',
    }),

    ...mapMutations({
      clearExerciseDetails: 'exercise/clearExerciseDetails',
    }),

    async getExerciseItemsCategory() {
      this.loading = true
      await this.getExerciseItemCategory()
      each(this.exerciseItemCategory.exercise_category, (value, key) => {
        this.exercise_category_details.push({
          label: value.category_name,
          value: value.id,
        })
      })

      each(this.exerciseItemCategory.exercise_items, (value, key) => {
        this.exercise_items.push({
          label: value.name,
          value: value.id,
        })
      })
      this.loading = false
    },

    async getExercise() {
      this.loading = true
      await this.getExerciseDetails(this.$route.params.id)
      this.form = new Form(this.exerciseDetails)
      this.form.video_id = this.exerciseDetails.video.id
      if (this.exerciseDetails.exercise_items) {
        const details = this.exerciseDetails.exercise_items
        const exerciseItemsId = details.map((exerciseItem) => exerciseItem.id)
        this.form.exercise_items_id = exerciseItemsId
      } else {
        this.form.exercise_items_id = 1
      }
      this.loading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      const formData = new FormData()

      each(this.form.$data(), (value, key) => {
        if (['photo', 'before_photo', 'after_photo'].includes(key)) {
          if (value && value.collection_name) {
            formData.append(key, null)
          } else {
            formData.append(key, value)
          }
        } else if (['video'].includes(key)) {
          formData.append('video_id', this.form.video_id)
        } else if (['exercise_items'].includes(key)) {
          each(value, (v, k) => {
            if (v.name) {
              formData.append('exercise_items_id[]', v.id)
            } else {
              formData.append('exercise_items_id[]', v)
            }
          })
        } else {
          formData.append(key, value)
        }
      })
      formData.append('_method', 'PUT')

      const data = {
        data: formData,
        id: this.$route.params.id,
      }

      this.updateExercise(data)
        .then(() => {
          this.showSnackbar('Exercise successfully updated!', 'success')

          setTimeout(() => {
            this.$router.push({
              name: 'exercises',
            })
          }, 1000)
        })
        .catch((err) => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    imageChanged(image) {
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.photoCover = reader.result
        this.form.photo = image
      }
    },

    setExerciseItem(value) {
      this.form.exercise_items = value
    },

    async getVideoDetails(page = 1) {
      let params = {
        page,
      }

      await this.getVideos(params)
      each(this.video, (value, key) => {
        this.video_details.push({
          label: value.name,
          value: value.id,
        })
      })
    },

    removeExercise() {
      this.loading = true

      this.deleteExercise(this.$route.params.id)
        .then(() => {
          this.$router.push({ name: 'exercises' })
        })
        .catch((error) =>
          this.showSnackbar(error.response.data.message, 'error')
        )
        .finally(() => (this.loading = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },
  },

  created() {
    this.getExerciseItemsCategory()
    this.getExercise()
    this.getVideoDetails()
  },

  destroyed() {
    this.clearExerciseDetails()
  },

  watch: {
    $route() {
      this.getExercise()
    },
  },
}
</script>
